/**
 * Navigation
 *
 * @global jQuery
 * @requires plugins/priority-nav.js
 * @author Matteo Casonato
 */


var nav = priorityNav.init({
    mainNavWrapper: ".js-navigation",
    breakPoint: 0,
    navDropdownLabel: '<span></span><span></span><span></span>',
    throttleDelay: '50'
});