/**
 * Sidebar
 *
 * @global jQuery
 * @requires plugins/jquery.accordion.js
 * @author Matteo Casonato
 */
 
 (function ($) {
	'use strict';

    var submenu = $('#js-accordionmenu');

    $('<span class="accordion" />').appendTo(submenu.find('li.dropdown').children('a, span'));

    submenu.find('li.active').addClass('open');

    submenu.accordion({
		panelSelector	: 'li.dropdown',
		titleSelector	: '> a > .accordion, > span > .accordion',
		contentSelector	: '> ul',
		activeClass		: 'open'
	});
    
}(jQuery));