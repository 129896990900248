/**
 * Cookiebar
 *
 * @summary Gestisce la barra dei cookie
 * @global jQuery, cookieManager
 * @author Daniele De Nobili
 */

(function ($) {
	'use strict';

    if (!window.cookieManager) {
        throw "La barra per la privacy policy necessita del cookieManager.";
    }

    // cookieBar
    var
        cookieBar = $('#cookiebar'),
        cookieButtons = $('#cookiebar-buttons'),
        cookieName = window.ponghoCookiePrivacy || 'pongho_privacy';

    function accept() {
        window.cookieManager.create(cookieName, 1, 365);
        cookieBar.addClass('cookiebar--hide');
    }

    cookieButtons.on('click', function (event) {
        event.preventDefault();

        accept();
    });

    if (!window.cookieManager.read(cookieName)) {
        cookieBar.removeClass('cookiebar--hide');
    }


    // Finestra modale per le condizioni
    var
        body = $('body'),
        html = $('html');

    cookieBar.on('click', '.cookiebar__link', function (event) {
        event.preventDefault();

        var
            modal = $('<div class="privacymodal" />'),
            overlay = $('<div class="privacymodal__overlay" />'),
            content = $('<div class="privacymodal__content" />'),
            footer = $('<div class="privacymodal__footer" />'),
            footerText = $('<p class="privacymodal__text" />').text(cookieBar.data('footer-text')),
            acceptButton = $('<button class="privacymodal__button" />').text(cookieBar.data('footer-button'));

        // struttura
        body.append(modal);
        modal.append(overlay);
        modal.append(content);

        content.append('<div class="privacymodal__iframe"><iframe src="' + this.href + '" frameborder="0" allowfullscreen /></div>');
        content.append(footer);

        footer.append(footerText);
        footer.append(acceptButton);

        // Evito lo scroll della pagina sottostante
        html.addClass('privacymodal--overflow');

        // conferma
        acceptButton.on('click', function () {
            // Rimuovo la finestra modale
            modal.remove();

            // Ripristino lo scroll sulla pagina sottostante
            html.removeClass('privacymodal--overflow');

            // Accetto le condizioni per la privacy policy
            accept();
        });
    });

}(jQuery));