/**
 * Google Maps
 *
 * @summary Mappa personalizzata con https://snazzymaps.com/
 * @global jQuery
 * @requires plugins/jquery.googlemaps.js
 * @author Daniele De Nobili
 */

(function ($) {
    'use strict';

    var maps = $('#js-gmap');

    if (!maps.length) {
        return;
    }

    window.onFirstUserInteraction(function(){
        var d = document,
            t = 'script';

        function l(u) {
            var j = d.createElement(t),
                s = d.getElementsByTagName(t)[0];

            j.src = u;
            j.async = 1;
            s.parentNode.insertBefore(j, s);
        }

        l('//maps.googleapis.com/maps/api/js?key=AIzaSyDrBGzoQY6gobmBLLHMN7uj2K81Rjt6Obs&v=3.exp&callback=initialize');
    });


    window.initialize = function () {
        maps.googlemaps();
    };

}(jQuery));