/**
 * Pongho Forms
 *
 * @requires plugins/pongho-form.js
 * @global jQuery, ga
 * @author Daniele De Nobili
 */

(function ($) {
	'use strict';

    $('.ajax-form').ponghoForm({
        wrapperClass: 'ajax-form-wrapper',
        onSubmit: function (form) {
            if (window.ga) {
                // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
                window.ga(
                    'send',
                    {
                        'hitType': 'event', // Required.
                        'eventCategory': 'form', // Required.
                        'eventAction': 'submit', // Required.
                        'eventLabel': form.data('ga-label') || form.prop('name'),
                        'page': window.location.pathname
                    }
                );
            }
        }
    });

}(jQuery));