/**
 * Pages
 *
 * @global jQuery
 * @requires plugins/jquery.matchHeight.js
 * @requires plugins/jquery.slick.js
 * @requires plugins/jquery.lightgallery.js
 * @author Matteo Casonato
 */

/* Gallery */
(function ($) {
	'use strict';

    $('#js-gallery').lightGallery({
        selector        : '.gallery__link',
        download        : false,
        thumbnail       : true
    });
    
    $('#js-gallery').slick({
        mobileFirst     : true,
        vertical		: false,
        arrows			: false,
        autoplay		: false,
        dots			: true,
        infinite		: false,
        speed			: 300,
        slidesToShow	: 1,
        slidesToScroll	: 1,
        responsive      : [
            {
                breakpoint    : 479,
                settings      : {
                    slidesToShow    : 2
                }
            },
            {
                breakpoint    : 767,
                settings      : {
                    slidesToShow    : 3
                }
            },
            {
                breakpoint    : 991,
                settings      : {
                    slidesToShow    : 4
                }
            }
        ]
    });

}(jQuery));


/* Home Page: Spotlight + Carousel Suggest Product */
(function ($) {
	'use strict';

	$('#js-spotlight').slick({
        mobileFirst     : true,
		vertical		: false,
		arrows			: false,
		autoplay		: true,
		dots			: true,
		infinite		: false,
		speed			: 300,
		slidesToShow	: 1
	});
    
}(jQuery));